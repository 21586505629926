

export default {
  name: 'FiltersSelected',
  props: {
    FilterSelected: {
      type: Array,
      default() {}
    }
  }
}
